'use client'

import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'

import { ACCEPTED_FILE_FORMATS } from '@/app/@modal/(.)chat/constants'
import { useAlert } from '@/components/atoms/PopUpAlert/useAlert'
import RichText from '@/components/atoms/RichText/RichText'
import RichTextBoldButton from '@/components/atoms/RichText/RichTextBoldButton'
import RichTextBulletListButton from '@/components/atoms/RichText/RichTextBulletListButton'
import RichTextContent from '@/components/atoms/RichText/RichTextContent'
import RichTextHeader from '@/components/atoms/RichText/RichTextHeader'
import RichTextHighlightButton from '@/components/atoms/RichText/RichTextHighlightButton'
import RichTextItalicButton from '@/components/atoms/RichText/RichTextItalicButton'
import { useRichTextContent } from '@/components/atoms/RichText/RichTextProvider'
import RichTextStrikeButton from '@/components/atoms/RichText/RichTextStrikeButton'
import RichTextSubmitButton from '@/components/atoms/RichText/RichTextSubmitButton'
import RichTextUnderlineButton from '@/components/atoms/RichText/RichTextUnderlineButton'
import CloseIcon from '@/components/icons/CloseIcon'
import PlusCircle from '@/components/icons/PlusCircleIcon'
import { ServerActionResult } from '@/lib/actions/ServerActionResult'
import { generateID } from '@/utils/generateID'
import { bytesToGreaterUnitString } from '@/utils/units'

import { MESSAGE_LENGTH_LIMIT } from '@/app/(panel)/chat/constants'
import { BoxProps } from '@/components/atoms/Box/Box'
import classNames from 'classnames'

import styles from './ChatMessage.module.css'

const sizes = {
  compact: styles.compact,
  default: styles.default,
} as const

type ChatMessageTextAreaSize = keyof typeof sizes

export type ChatMessageTextAreaProps = BoxProps<'form'> & {
  sendMessage: (formData: FormData) => Promise<ServerActionResult>
  receiverUserId?: number
  groupId?: number
  size?: ChatMessageTextAreaSize
}

type ChatMessageFile = {
  id: string
  name: string
  size: string
}

const ChatMessageTextArea = ({
  sendMessage,
  receiverUserId,
  groupId,
  size = 'default',
  className,
  ...props
}: ChatMessageTextAreaProps) => {
  const { editor } = useRichTextContent()

  const filesInputRef = useRef<HTMLInputElement>(null)

  const [message, setMessage] = useState('')
  const [files, setFiles] = useState<ChatMessageFile[]>([])

  const { displayErrorAlert } = useAlert()

  useEffect(() => {
    if (editor) {
      editor.on('update', handleWrite)
    }

    return () => {
      if (editor) {
        editor.off('update', handleWrite)
      }
    }
  })

  const handleWrite = (event: any) => {
    const messageText = event.editor.getText()
    const trimmedText = messageText.slice(0, MESSAGE_LENGTH_LIMIT)

    if (messageText.length > MESSAGE_LENGTH_LIMIT) {
      displayErrorAlert({
        title: 'Reached message characters limit',
        description: 'Please send a message with less than 3000 characters.',
      })

      event.editor.commands.setContent(trimmedText)
      setMessage(trimmedText)
      return
    }

    setMessage(messageText)
  }

  const handleSendMessage = useCallback(
    async (formData: FormData) => {
      await sendMessage(formData)

      editor?.commands.clearContent()
      setMessage('')
      setFiles([])

      if (filesInputRef.current) {
        filesInputRef.current.value = ''
      }
    },
    [editor, sendMessage]
  )

  const handleChangeFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files ? Array.from(event.target.files) : []

    if (!!files.length) {
      setFiles(
        files.map((file) => ({
          id: generateID(),
          name: file.name,
          size: bytesToGreaterUnitString(file.size),
        }))
      )
    }
  }

  const handleExcludeFile = (idToExclude: string) => {
    const filesWithoutExcluded = files.filter((file) => file.id !== idToExclude)
    setFiles(filesWithoutExcluded)
  }

  const formRef = useRef<HTMLFormElement>(null)

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const isCommandEnter = e.key === 'Enter' && (e.metaKey || e.ctrlKey)

      if (isCommandEnter && editor?.isFocused && formRef.current) {
        e.preventDefault()
        e.stopPropagation()

        handleSendMessage(new FormData(formRef.current))
      }
    },
    [editor, handleSendMessage]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <form
      ref={formRef}
      className={classNames(
        className,
        styles.messageTextAreaContainer,
        sizes[size]
      )}
      action={handleSendMessage}
      {...props}
    >
      <input type="hidden" name="receiverUserId" value={receiverUserId} />
      <input type="hidden" name="groupId" value={groupId} />

      <RichText
        name="body"
        fixedHeight="5rem"
        className={styles.messageTextArea}
        required
      >
        <RichTextHeader className={styles.messageTextAreaMenuBar}>
          <RichTextBoldButton />
          <RichTextItalicButton />
          <RichTextUnderlineButton />
          <RichTextStrikeButton />
          <RichTextBulletListButton />
          <RichTextHighlightButton />
        </RichTextHeader>

        <RichTextContent className={styles.messageTextAreaContent} />
      </RichText>

      {!!files.length && (
        <div className={styles.messageFilesArea}>
          {files.map((file) => (
            <div key={file.name} className={styles.messageFile}>
              <strong>{file.name}</strong> <span>{file.size}</span>
              <CloseIcon onClick={() => handleExcludeFile(file.id)} />
            </div>
          ))}
        </div>
      )}

      <div className={styles.messageActions}>
        <input
          ref={filesInputRef}
          name="file_data"
          type="file"
          id="fileInput"
          multiple
          hidden
          onChange={handleChangeFileInput}
          accept={ACCEPTED_FILE_FORMATS}
        />

        <label htmlFor="fileInput" className={styles.fileInputLabel}>
          <PlusCircle size={16} />
          <span className={styles.fileInputLabelText}>
            Add file or attachment
          </span>
        </label>

        <div className={styles.charCountAndSend}>
          <span className={styles.characterCount}>
            {message.length}/{MESSAGE_LENGTH_LIMIT}
          </span>

          <RichTextSubmitButton className={styles.sendButton} />
        </div>
      </div>
    </form>
  )
}

export default ChatMessageTextArea
