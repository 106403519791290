'use client'
import { ReactNode, useCallback } from 'react'

import { useAlert } from '@/components/atoms/PopUpAlert/useAlert'
import CopyIcon from '@/components/icons/CopyIcon'
import { clearHtml } from '@/utils/strings'

import styles from './ChatMessage.module.css'

type ChatMessageActionProps = {
  messageBody: ReactNode
}

const ChatMessageAction = ({ messageBody }: ChatMessageActionProps) => {
  const { displaySuccessAlert, displayErrorAlert } = useAlert()

  const handleCopy = useCallback(async () => {
    if (!messageBody?.toString()) {
      return
    }
    try {
      const clearedText = clearHtml(messageBody?.toString())
      await navigator.clipboard.writeText(clearedText)
      displaySuccessAlert({
        title: 'Message copied!',
      })
    } catch (error) {
      displayErrorAlert({
        title: 'Error copying message',
        description: error as string,
      })
    }
  }, [displaySuccessAlert, displayErrorAlert, messageBody])

  return (
    <CopyIcon
      className={styles.copyIcon}
      color="var(--color-neutral-300)"
      onClick={handleCopy}
    />
  )
}

export default ChatMessageAction
