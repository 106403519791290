'use client'

import { BoxProps } from '@/components/atoms/Box/Box'
import Tooltip from '@/components/atoms/Tooltip/Tooltip'
import ErrorIcon from '@/components/icons/ErrorIcon'
import classNames from 'classnames'

import styles from './FloatingChatButton.module.css'

const FloatingChatIconButtonError = ({ className }: BoxProps<'div'>) => {
  const message =
    'There was an error loading the chat window. Click here to try again.'

  return (
    <Tooltip content={message}>
      <div
        role="alert"
        aria-live="polite"
        className={classNames(className, styles.container)}
        aria-label={message}
      >
        <ErrorIcon
          className={styles.icon}
          width="2rem"
          height="2rem"
          color="var(--color-alert)"
          onClick={(e) => {
            e.preventDefault()

            // router.refresh was not working for some reason
            // @ts-expect-error firefox accepts "true" to clear the cache
            window.location.reload(true)
          }}
        />
      </div>
    </Tooltip>
  )
}

export default FloatingChatIconButtonError
